import { Steps, message, Radio } from 'antd';
import React, { useState } from 'react'
import { AccountType } from '../../models/AccountType';
import { Hotel } from '../../models/Hotel';
import SelectAccountTypes from './SelectAccountTypes/SelectAccountTypes';
import SelectHotels from './SelectHotels/SelectHotels';
import { useQuery } from 'react-query';
import { HotelsAPI } from '../../api/hotels';
import Loading from '../../components/Loading/Loading';
import Review from './Review/Review';
import './styles.css'
import SelectAction from './SelectAction/SelectAction';

const AccountTypes: React.FC = () => {
  const { data: allHotels, isLoading, error } = useQuery<Hotel[]>('getAllHotels', HotelsAPI.getAllHotels);
  const [step, setSteps] = useState(0)

  const [action, setAction] = useState('')
  const [accountTypes, setAccountTypes] = useState<AccountType[]>([])
  const [hotels, setHotels] = useState<Hotel[]>([])

  const onSelectAction = (action: string) => {
    setAction(action)
    setSteps(1) 
  }

  const onFinishAccountTypes = (selectedAccountTypes: AccountType[]) => {
    setAccountTypes(selectedAccountTypes)
    setSteps(2)
  }

  const onFinishHotels = (selectedHotels: Hotel[]) => {
    setHotels(selectedHotels)
    setSteps(3)
  }

  const onReset = () => {
    setAccountTypes([])
    setHotels([])
    setSteps(0)
  }

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    message.error('Failed to get hotels, error: ' + error)
  }
  
  return (
    <>
      <div className='page-container'>
        <div className='at-container'>
          <Steps size="small" current={step}>
              <Steps.Step title="Select Action" />
              <Steps.Step title="Select Account Types" />
              <Steps.Step title="Select Hotels" />
              <Steps.Step title="Review" />
          </Steps>

          {step === 0 && (
            <SelectAction initialAction={action} onNext={onSelectAction} />
          )}
          {step === 1 && (
            <SelectAccountTypes initialAccountTypes={accountTypes} onBack={() => setSteps(0)} onNext={onFinishAccountTypes} />
          )}
          {step === 2 && (
            <SelectHotels datasource={allHotels} initialHotels={hotels} onBack={() => setSteps(1)} onNext={onFinishHotels} />
          )}
          {step === 3 && (
            <Review accountTypes={accountTypes.map(at => at.name)} hotels={hotels} action={action} onReset={onReset} onBack={() => setSteps(2)}/>
          )}
        </div>
      </div>
    </>
  )
}

export default AccountTypes;