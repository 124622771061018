import { Button, Result } from "antd"

interface SuccessProps {
    onOk: () => void;
    onBack: () => void;
}
const Success: React.FC<SuccessProps> = ({ onOk, onBack }) => {
    return (
        <Result
            status="success"
            title="Successfully Updated Accounts"
            subTitle="All accounts have been updated successfully."
            extra={[
                <Button type="primary" key="ok" onClick={() => onOk()}>
                    Go to Accounts
                </Button>,
                <Button key="back" onClick={() => onBack()}>Back</Button>,
            ]}
        />
    )
}

export default Success;