import { Button, Menu, Layout } from 'antd';
import BonotelLogo from '../BonotelLogo';
import './style.css';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

const signOut = () => {
  Auth.signOut()
    .then()
    .catch(err => console.error("error on signing out: " + err));
}

const Header = () => {
  const navigate = useNavigate();
  
  const navigateToPage = (key: string) => {
    navigate(key)
  }

  return (
    <>
      <Layout.Header className='header-background'>
        <div className="logo">
          <BonotelLogo />
        </div>
        <Menu
          mode="horizontal"
          onClick={({ key }) => { navigateToPage(key) }}
          items={[
            {
              label: 'Accounts',
              key: '/accounts',
            }
          ]}
        />
          <Menu
            className='ending'
            selectable={false}
            mode='horizontal'
            style={{ position: 'absolute', top: 0, right: 0, width: '150px' }}
            items={[
              {
                label: (
                  <Button onClick={signOut}>Logout</Button>
                ),
                key: 'logout'
              }
            ]}>
          </Menu>
      </Layout.Header>
    </>
  );
};

export default Header;
