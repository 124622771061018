import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal, Table, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import time from "../../../lib/time";
import { Account } from "../../../models/Account";
const { Text } = Typography;

interface AccountTableProps {
    accounts: Account[];
    onDelete: (account: Account) => void
}
const AccountTable: React.FC<AccountTableProps> = ({ accounts, onDelete }) => {
    const navigate = useNavigate();

    const onViewAccount = (account: any) => {
        navigate("/accounts/" + account.id)
    }

    const onDeleteAccount = (account: Account) => {
        Modal.confirm({
            title: "Are you sure you want to delete this account?",
            okText: "Yes",
            okType: "danger",
            onOk: () => {
                onDelete(account)
            }
        })
    }

    return (
        <>
            <Table
                rowKey='id'
                dataSource={accounts}
                pagination={{ position: ['bottomCenter'] }}
                style={{width: '1300px'}}
            >
                <Table.Column title={"ID"} dataIndex={"id"} key={"id"} />
                <Table.Column title={"Account name"} dataIndex={"name"} key={"name"} />
                <Table.Column title={"Type"} dataIndex={"type"} key={"type"} />
                <Table.Column title={"Status"} dataIndex={"status"} key={"status"} />
                <Table.Column title={"Added on"} dataIndex={"creationDate"} key={"creationDate"} render={(date) => (
                    <Text>{time.convertTimestampToISOString(date)} </Text>
                )} />
                <Table.Column title={"Actions"} render={(record) => (
                    <>
                        <EyeOutlined style={{ color: "#6a207f" }} onClick={() => {
                            onViewAccount(record)
                        }} />
                        <DeleteOutlined style={{ color: "red", marginLeft: 15 }} onClick={() => {
                            onDeleteAccount(record)
                        }} />
                    </>
                )}/>
            </Table>
        </>
    )
}

export default AccountTable;