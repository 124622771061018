import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Header from './components/Header';
import Footer from './components/Footer';

import './App.css'
import '@aws-amplify/ui-react/styles.css';

import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import handleSignInEvents from './lib/api/listener';
import AccountsList from './pages/AccountsPage/AccountsPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import AccountTypes from './pages/AccountTypesPage/AccountTypesPage';
import AccountPage from './pages/AccountPage/AccountPage';

Amplify.configure(config);

const theme = {
  token: {
    colorPrimary: '#6a207f',
  },
}

const queryClient = new QueryClient();

handleSignInEvents()

const App = () => {
  return (
    <ConfigProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <BrowserRouter>
            <Header />
            <Layout>
              <Routes>
                <Route path='/' element={<AccountsList />} />
                <Route path='/accounts' element={<AccountsList />} />
                <Route path='/accounts/:id' element={<AccountPage />} />
                <Route path='/account-types' element={<AccountTypes />} />
                <Route path='*' element={<NotFoundPage />} />
              </Routes>
            </Layout>
            <Footer />
          </BrowserRouter>
        </Layout>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default withAuthenticator(App, { hideSignUp: true });