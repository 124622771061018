import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const withAuthRedirect = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const RedirectComponent: React.FC<P> = (props) => {

    useEffect(() => {
      const checkAuthStatus = async () => {
        try {
          let timerId: number;
          const user = await Auth.currentAuthenticatedUser();
          if (user) {
            const now = Math.floor(Date.now() / 1000) // Convert timestamp to seconds
            const sessionTTL = Math.abs(now - user.signInUserSession.idToken.payload.exp)

            timerId = window.setTimeout(async () => {
              await Auth.signOut();
              await Auth.federatedSignIn(); // Redirect to Cognito login page
              window.clearTimeout(timerId)
            }, sessionTTL * 1000)
          }
        } catch (error) {
          console.log('session timeout')
        }
      };

      checkAuthStatus();
    });

    return <WrappedComponent {...props} />;
  };

  return RedirectComponent;
};

export default withAuthRedirect;
