import './Loading.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

const spinIcon = <LoadingOutlined type="loading" style={{ fontSize: 32 }} spin />;

const Loading = () => {
  return (
    <Spin className='loading-icon' indicator={spinIcon} />
  )
}

export default Loading;
