import { CheckOutlined, ClockCircleFilled, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Account, AccountHotel } from "../../../../models/Account";
import { Hotel } from "../../../../models/Hotel";
import ViewHotelsModal from "../ViewHotels/ViewHotels";

export enum Status {
    PENDING = "Pending",
    PROCESSING = "Processing",
    PROCESSED = "Processed",
    ERROR = "Error"
}
export interface AccountUpdateDataType {
    key: React.Key;
    name: string;
    type: string;
    currentHotels: AccountHotel[];
    newHotels: Hotel[];
    status: Status;
}

interface AccountsTableProps {
    accounts: Map<string, AccountUpdateDataType>
    hotels: Hotel[]
    action: string
}

const AccountsTable: React.FC<AccountsTableProps> = ({ accounts, hotels, action }) => {
    const [isViewHotelsModalOpen, setViewHotelsModalOpen] = useState(false)
    const [selectedAccountHotels, setSelectedAccountHotels] = useState<AccountHotel[]>([])
    const [dataSource, setDataSource] = useState<AccountUpdateDataType[]>([])

    useEffect(() => {
        setDataSource(Array.from(accounts.values()))
    }, [accounts])

    const columns: ColumnsType<AccountUpdateDataType> = [
        {
            title: 'Account Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Account Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Allowed Hotels',
            render: ((_: any, record: AccountUpdateDataType) => <>
                <Button type="link" onClick={() => {
                    setViewHotelsModalOpen(true);
                    setSelectedAccountHotels(record.currentHotels)
                }}>View</Button>
            </>)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status => {
                return (<Tooltip title={status}>
                    {status === Status.PENDING ?
                        (<ClockCircleFilled style={{ color: 'orange' }} />) :
                        status === Status.PROCESSING ?
                            (<LoadingOutlined style={{ color: 'blue' }} />) :
                            status === Status.PROCESSED ?
                                (<CheckOutlined style={{ color: 'green' }} />) :
                                status === Status.ERROR ?
                                    (<CloseOutlined style={{ color: 'red' }} />) : (<></>)
                    }
                </Tooltip>)
            })
        },
    ]

    return (
        <>
            <Table dataSource={dataSource} columns={columns} />
            <ViewHotelsModal isOpen={isViewHotelsModalOpen} onClose={() => setViewHotelsModalOpen(false)} currentHotels={selectedAccountHotels} newHotels={hotels} action={action} />
        </>
    )
}

export default AccountsTable