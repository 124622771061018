import { message } from "antd";
import { Auth, Hub } from "aws-amplify";

const handleSignInEvents = () => Hub.listen('auth', (hubData) => {
    switch (hubData.payload.event) {
        case 'signIn':
            const userEmail = hubData.payload.data.attributes.email
            localStorage.setItem("userEmail", userEmail)

            // displays a friendly message after every login
            message.info("Welcome " + userEmail + "!")

            // saves the user token in local storage to be accessed everywhere after the login
            Auth.currentSession()
                .then((res) => {
                    localStorage.setItem("userToken", res.getIdToken().getJwtToken())
                })
                .catch(e => console.log(e))

            break;
        case 'tokenRefresh':
            Auth.currentSession()
                .then((res) => {
                    localStorage.setItem("userToken", res.getIdToken().getJwtToken())
                })
                .catch(e => console.log(e))
            break;
    }
});

export default handleSignInEvents;