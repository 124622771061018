import './style.css';

const Footer = () => {
  return (
    <footer className="footer">
      <article className="footer__website-link">
        <a href="https://www.bonotel.com"> bonotel.com </a>
      </article>
      <article className="footer-copyright">
        <p> © 2023 Bonotel. All rights reserved. </p>
      </article>
    </footer>
  );
};

export default Footer;
