import { API } from 'aws-amplify';

const getHeaders = () => {
	return {
		"Content-Type": 'application/json',
		Authorization: localStorage.getItem("userToken")
	}
}

const hotelsApiConfig = {
	apiName: "hotels",
	timeout: 15000, // in ms
};

export const HotelsAPI = {
	getAllHotels: (): Promise<any> => API.get(hotelsApiConfig.apiName, '/hotels', {
		headers: getHeaders(), timeout: hotelsApiConfig.timeout,
	}),
}