import { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { Account } from '../../models/Account';
import { useNavigate } from 'react-router-dom';
import AccountFormModal from './AccountFormModal/AccountFormModal';
import { PlusOutlined } from '@ant-design/icons';
import { AccountsAPI } from '../../api/accounts';
import Loading from '../../components/Loading/Loading';
import Search from 'antd/es/input/Search';
import AccountTable from './AccountTable/AccountTable';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import './style.css'
import withAuthRedirect from '../../components/Auth/AuthRedirect';

const AccountsList: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: accounts, isLoading, error } = useQuery<Account[]>('getAccounts', () => AccountsAPI.getAccounts("", [], []))
  const [isAccountFormModalOpen, setAccountFormModalOpen] = useState(false)

  const [accountsDatasource, setAccountsDatasource] = useState<Account[]>([]);

  useEffect(() => {
    if (accounts) {
      setAccountsDatasource(accounts)
    }
  }, [accounts])

  const handleSuccessAccountModal = () => {
    setAccountFormModalOpen(false)
    queryClient.invalidateQueries("getAccounts")
  }

  const handleCloseModal = () => {
    setAccountFormModalOpen(false);
  }

  const handleOpenModal = () => {
    setAccountFormModalOpen(true);
  }

  const { mutate } = useMutation({
    mutationFn: (id: string) => {
      return AccountsAPI.updateAccountStatus(id, "INACTIVE")
    },
    onSuccess: () => {
      message.success('Account removed successfuly')
      queryClient.invalidateQueries("getAccounts")
    },
    onError: (e: any) => {
      message.error('Failed to remove account, error: ' + e)
    }
  })

  const onDeleteAccount = (account: Account) => {
    mutate(account.id)
  }

  const onSearch = (search: any) => {
    const filteredAccounts = accounts?.filter(c =>
      c.id.toUpperCase().includes(search?.toUpperCase())
      || c.name.toUpperCase().includes(search?.toUpperCase())
      || c.type.toUpperCase().includes(search?.toUpperCase())
      || c.status.toUpperCase().includes(search?.toUpperCase())
    )
    if (filteredAccounts) {
      setAccountsDatasource(filteredAccounts)
    }
  }

  if (error) {
    message.error('Failed to get accounts, error: ' + error)
  }

  if (isLoading) {
    return (<Loading />)
  }

  return (
    <>
      <div className='page-container'>
        <div className='acc-container'>
          <AccountFormModal isOpen={isAccountFormModalOpen} onSuccess={handleSuccessAccountModal} onCancel={handleCloseModal} />
          <div className='acc-buttons'>
            <Search
              allowClear
              placeholder="Search customer"
              enterButton="Search"
              size="middle"
              onChange={e => onSearch(e.target.value)}
              onSearch={onSearch}
              style={{ margin: '0 16px 0 0', width: '25%' }} />
            <Button type='primary' icon={<PlusOutlined />} onClick={handleOpenModal} style={{ margin: '0 16px 0 0' }}>Create Account</Button>
            <Button type='primary' onClick={() => navigate("/account-types")} >Bulk Add/Remove Hotels</Button>
          </div>

          <AccountTable accounts={accountsDatasource} onDelete={onDeleteAccount} />
        </div>
      </div>
    </>
  );
}

export default withAuthRedirect(AccountsList);