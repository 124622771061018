import { Button, Select, Form, Modal, message } from 'antd';
import { Input } from 'antd';
import './style.css';
import { Account } from '../../../models/Account';
import { AccountsAPI } from '../../../api/accounts';
import './style.css'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
  
};

interface AccountFormModalProps {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const AccountFormModal: React.FC<AccountFormModalProps> = ({ isOpen, onSuccess, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const account = values as Account
    account.allowedHotels = []
    AccountsAPI.createAccount(account)
      .then(() => {
        message.success('Account created succesfully')
        onSuccess()
      })
      .catch(e => message.error('Falied to create an account error: ', e))
  };

  const AccountForm = () => (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      layout="horizontal"
      style={{ maxWidth: 900 }}
      requiredMark={true}
    >
      <Form.Item name='id' label="ID" rules={[{ required: true }]}>
        <Input name='id'/>
      </Form.Item>
      <Form.Item name='name' label="Name" rules={[{ required: true }]}>
        <Input name='name'/>
      </Form.Item>
      <Form.Item name={"type"} label="Type" rules={[{ required: true }]}>
        <Form.Item name={"type"} rules={[{ required: true }]} noStyle> 
        <Select
            placeholder="Select type"
            options={[
              { value: 'CG', label: 'CG' },
              { value: 'CUSTOM 1', label: 'CUSTOM 1' },
              { value: 'ZFG', label: 'ZFG' },
              { value: 'PINT', label: 'PINT' },
            ]}
            style={{ width: '35%', float: 'left', marginRight: ' 50px' }}
          />
        </Form.Item>
        <Form.Item name={"status"} label={"Status"} rules={[{ required: true }]}>
          <Select
            placeholder="Select status"
            options={[
              { value: 'ACTIVE', label: 'ACTIVE' },
              { value: 'INACTIVE', label: 'INACTIVE' },
            ]}
            style={{ width: '100%', float: 'left' }}
          />
        </Form.Item>
      </Form.Item>
    </Form>
  )

  return (
    <Modal
      title='New Account'
      open={isOpen}
      width={720}
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="create" onClick={() => form.submit()} type="primary">
          Create
        </Button>
      ]}
    >
      <AccountForm />
    </Modal>
  )
}

export default AccountFormModal;