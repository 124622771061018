import { Col, Modal, Row, Typography } from "antd";
import AccountHotelTable from "../../../../components/AccountHotelTable/AccountHotelTable";
import HotelTable from "../../../../components/HotelTable/HotelTable";
import { AccountHotel } from "../../../../models/Account";
import { Hotel } from "../../../../models/Hotel";
const { Title } = Typography;

interface ViewHotelsModalProps {
    isOpen: boolean;
    onClose: () => void
    currentHotels: AccountHotel[];
    newHotels: Hotel[];
    action: string
}
const ViewHotelsModal: React.FC<ViewHotelsModalProps> = ({ isOpen, onClose, currentHotels, newHotels, action }) => {
    return (
        <Modal open={isOpen} onCancel={onClose} width={1300} footer={null}>
            <Row justify={'center'} align="top">
                <Col span={12}>
                    <Title level={4} style={{ margin: '20px 0 0 20px' }}>
                        Current hotels:
                    </Title>
                    <AccountHotelTable datasource={currentHotels} actionsDisabled={true} />
                </Col>
                <Col span={12}>
                    <Title level={4} style={{ margin: '20px 0 0 20px' }}>
                        {action === 'add' ? <>Hotels included:</> : action === 'remove' ? <>Hotels removed:</> : <></>}
                    </Title>
                    <HotelTable datasource={newHotels} />
                </Col>
            </Row>
        </Modal>
    )
}

export default ViewHotelsModal;