import { Table, Transfer, TransferProps } from "antd";
import Column from "antd/es/table/Column";
import { TableRowSelection } from "antd/es/table/interface";
import difference from "lodash/difference";
import { Hotel } from "../../../models/Hotel";

interface TableTransferProps extends TransferProps<Hotel> {
    dataSource: Hotel[];
}

const TransferTable = ({ ...restProps }: TableTransferProps) => (
    <Transfer {...restProps}>
        {({
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {

            const rowSelection: TableRowSelection<Hotel> = {
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .map(({ hotelCode }) => hotelCode);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys as string[], selected);
                },
                onSelect({ hotelCode }, selected) {
                    onItemSelect(hotelCode as string, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    rowSelection={rowSelection}
                    rowKey={'hotelCode'}
                    dataSource={filteredItems}
                    size="middle"
                    style={{ pointerEvents: listDisabled ? 'none' : undefined }}
                    onRow={({ hotelCode }) => ({
                        onClick: () => {
                            onItemSelect(hotelCode as string, !listSelectedKeys.includes(hotelCode as string));
                        },
                    })}
                >
                    <Column title={'Hotel code'} dataIndex={'hotelCode'} key={'hotelCode'} sorter={(a: Hotel, b: Hotel) => Number(a.hotelCode) - Number(b.hotelCode)} />
                    <Column title={'Hotel Name'} dataIndex={'hotelName'} key={'hotelName'} sorter={(a: Hotel, b: Hotel) => b.hotelName.localeCompare(a.hotelName)} />
                </Table>
            );
        }}
    </Transfer>
);

export default TransferTable;