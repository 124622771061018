import { API } from 'aws-amplify';
import { Account, AccountHotel } from '../models/Account';

const getHeaders = () => {
	return {
		"Content-Type": 'application/json',
		Authorization: localStorage.getItem("userToken")
	}
}

const accAPIConfig = {
	apiName: "accounts",
	timeout: 15000, // in ms
};

const buildGetAccountsURI = (status: string, accountTypes: string[], includes: string[]) => {
	let uri = "/accounts";
  
	const queryParams = [];
  
	if (status) {
	  queryParams.push(`status=${encodeURIComponent(status)}`);
	}
  
	if (accountTypes && accountTypes.length > 0) {
	  const types = accountTypes.map((type) => encodeURIComponent(type)).join(",");
	  queryParams.push(`accountTypes=${types}`);
	}
  
	if (includes && includes.length > 0) {
	  const includeParams = includes.map((include) => encodeURIComponent(include)).join(",");
	  queryParams.push(`includes=${includeParams}`);
	}
  
	if (queryParams.length > 0) {
	  uri += `?${queryParams.join("&")}`;
	}
  
	return uri;
  }
  
export const AccountsAPI = {
	getAccounts: (status: string, accountTypes: string[], includes: string[]): Promise<any> => API.get(accAPIConfig.apiName, buildGetAccountsURI(status, accountTypes, includes), {
		headers: getHeaders(), timeout: accAPIConfig.timeout,
	}),
	getAccountById: (id: string): Promise<any> => API.get(accAPIConfig.apiName, `/accounts/${id}`, {
		headers: getHeaders(), timeout: accAPIConfig.timeout,
	}),
	getAccountAllowedHotels: (id: string): Promise<any> => API.get(accAPIConfig.apiName, `/accounts/${id}/allowedHotels`, {
		headers: getHeaders(), timeout: accAPIConfig.timeout,
	}),
	createAccount: (account: Account): Promise<any> => API.post(accAPIConfig.apiName, `/accounts`, {
		headers: getHeaders(), timeout: accAPIConfig.timeout, body: account
	}),
	updateAccount: (id: string, account: Account): Promise<any> => API.put(accAPIConfig.apiName, `/accounts/${id}`, {
		headers: getHeaders(), timeout: accAPIConfig.timeout, body: account
	}),
	updateAccountStatus: (id: string, status: string): Promise<any> => API.put(accAPIConfig.apiName, `/accounts/${id}/status`, {
		headers: getHeaders(), timeout: accAPIConfig.timeout, body: {status: status}
	}),
	updateAccountAllowedHotels: (id: string, hotels: AccountHotel[]): Promise<any> => API.put(accAPIConfig.apiName, `/accounts/${id}/allowedHotels`, {
		headers: getHeaders(), timeout: accAPIConfig.timeout, body: hotels
	}),
	deleteAccount: (id: string): Promise<any> => API.del(accAPIConfig.apiName, `/accounts/${id}`, {
		headers: getHeaders(), timeout: accAPIConfig.timeout
	}),
};