import { Button, Popconfirm, Transfer } from "antd";
import { useEffect, useState } from "react"
import { AccountType } from "../../../models/AccountType";
import "./styles.css"

const ACCOUNT_TYPES: AccountType[] = [
  { name: 'CG' },
  { name: 'CUSTOM 1' },
  { name: 'ZFG' },
  { name: 'PINT' },
]

interface AccountTypeTransferTableProps {
  initialAccountTypes: AccountType[];
  onNext: (accountTypes: AccountType[]) => void
  onBack: () => void
}

const AccountTypeTransfer: React.FC<AccountTypeTransferTableProps> = ({ onNext: onNext, initialAccountTypes, onBack }) => {
  const [selectedAccountTypes, setSelectedAccountTypes] = useState<string[]>([]);

  useEffect(() => {
    setSelectedAccountTypes(initialAccountTypes.map(a => a.name))
  }, [])

  const onChangeAccountTypes = (selected: string[]) => {
    setSelectedAccountTypes(selected)
  }

  const onClickNext = () => {
    const accountTypes = ACCOUNT_TYPES.filter(a => selectedAccountTypes.includes(a.name))
    onNext(accountTypes)
  }

  const onResetAccounts = () => {
    setSelectedAccountTypes([])
  }

  return (
    <div className='at-content'>
      <div className="sat-container">
        <div>
          <Transfer
            dataSource={ACCOUNT_TYPES}
            titles={['Account types', 'Selected Account types']}
            listStyle={{
              width: 310,
            }}
            rowKey={(item) => item.name}
            targetKeys={selectedAccountTypes}
            onChange={onChangeAccountTypes}
            render={(item) => item.name}
          />
          <div className="sat-buttons">
            <Popconfirm
              title="Reset"
              description="Are you sure to reset?"
              onConfirm={onResetAccounts}
              onCancel={() => console.log('no')}
              okText="Yes"
              cancelText="No"
            >
              <Button>Reset</Button>
            </Popconfirm>
            <Button style={{ marginLeft: '10px' }} onClick={onBack}>Back</Button>
            <Button type='primary' style={{ marginLeft: '10px' }} disabled={selectedAccountTypes.length < 1} onClick={onClickNext}>Next</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountTypeTransfer;