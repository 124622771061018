import { Button, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import HotelTransferTable from "../../../components/HotelTransferTable/HotelTransferTable";
import { Hotel } from "../../../models/Hotel";
import "./styles.css"

interface SelectHotelsProps {
    datasource: Hotel[] | undefined;
    initialHotels: Hotel[]
    onBack: () => void
    onNext: (hotels: Hotel[]) => void
}

const SelectHotels: React.FC<SelectHotelsProps> = ({ datasource, initialHotels, onBack, onNext }) => {
    const [selectedHotels, setSelectedHotels] = useState<Hotel[]>(initialHotels);

    useEffect(() => {
        setSelectedHotels(initialHotels)
    }, [])

    const onChangeHotels = (selected: Hotel[]) => {
        setSelectedHotels(selected)
    }

    const onClickNext = () => {
        onNext(selectedHotels)
    }

    const onResetHotels = () => {
        setSelectedHotels([])
    }

    return (
        <div className="sh-container">
            <HotelTransferTable datasource={datasource} initialHotels={selectedHotels} onChange={onChangeHotels} />
            <div className="sh-buttons">
                <Popconfirm
                    title="Reset"
                    description="Are you sure to reset?"
                    onConfirm={onResetHotels}
                    onCancel={() => console.log('no')}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button>Reset</Button>
                </Popconfirm>
                <Button style={{ marginLeft: '10px' }} onClick={onBack}>Back</Button>
                <Button type='primary' style={{ marginLeft: '10px' }} disabled={selectedHotels.length < 1} onClick={onClickNext}>Next</Button>
            </div>
        </div>
    )
}

export default SelectHotels;