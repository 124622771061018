import React, { useEffect, useState } from 'react';
import { Hotel } from '../../models/Hotel';
import TransferTable from './TransferTable/TransferTable';
import './styles.css';

interface HotelTransferTableProps  {
    datasource: Hotel[] | undefined;
    initialHotels: Hotel[];
    onChange: (hotels: Hotel[]) => void
}

const HotelTransferTable: React.FC<HotelTransferTableProps> = ({ datasource, initialHotels, onChange }) => {
    const [hotels, setHotels] = useState<Hotel[]>([]);
    const [selectedHotelIds, setSelectedHotelIds] = useState<string[]>([]);

    useEffect(() => {
       setHotels(datasource ? datasource : [])
    }, [datasource])

    useEffect(() => {
        setSelectedHotelIds(initialHotels?.map(h => h.hotelCode))
    }, [initialHotels])

    const onChangeHotels = (selected: string[]) => {
        setSelectedHotelIds(selected);

        const selectedHotels:Hotel[] = hotels.filter(h => selected.includes(h.hotelCode))
        onChange(selectedHotels)    
    };

    return (
        <>
            <TransferTable
                dataSource={hotels}
                rowKey={(item) => item.hotelCode}
                targetKeys={selectedHotelIds}
                disabled={false}
                showSearch={true}
                onChange={onChangeHotels}
                filterOption={(inputValue, item) =>
                    item.hotelName!.toUpperCase().indexOf(inputValue?.toUpperCase()) > -1 || item.hotelCode!.indexOf(inputValue) > -1
                }
            />
        </>
    );
};

export default HotelTransferTable;