import { Button, Col, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { AccountHotel } from "../../../models/Account";
import { Hotel } from "../../../models/Hotel";
import HotelTransferTable from "../../../components/HotelTransferTable/HotelTransferTable";

const mapAccountHotelsToHotels = (accountHotels: AccountHotel[]) => {
    const hotels = accountHotels?.map(h => { return {
        hotelCode: h.hotelCode,
        hotelName: h.hotelName
    } as Hotel})
    
    if (hotels) {
        return hotels;
    }

    return []
}

interface HotelTransferTableModalProps {
    isOpen: boolean;
    allHotels: Hotel[];
    initialHotels: AccountHotel[];
    onFinish: (selectedHotels: AccountHotel[]) => void;
    onCancel: () => void;
}
const HotelTransferTableModal: React.FC<HotelTransferTableModalProps> = ({ isOpen, allHotels, initialHotels, onFinish, onCancel }) => {
    const [hotelsDatasource, setHotelsDatasource] = useState<Hotel[]>([])
    const [hotels, setHotels] = useState<Hotel[]>(mapAccountHotelsToHotels(initialHotels))

    useEffect(() => {
        setHotelsDatasource(allHotels)
    }, [allHotels])

    const onFinishHotels = () => {
        const newHotels = hotels.map(h => { return {
            hotelCode: h.hotelCode,
            hotelName: h.hotelName
        } as Hotel})

        if (newHotels) {
            onFinish(newHotels as AccountHotel[])
            onCancel()
        }
    }

    const onResetHotels = () => {
        setHotelsDatasource(allHotels)
    }

    const onChange = (selectedHotels: Hotel[]) => {
        setHotels(selectedHotels)
    }

    return (
        <div className='at-content'>
            <Modal open={isOpen} width={1000} footer={null} onCancel={onCancel}>
                <Row justify="space-between" align="top" gutter={[16, 48]}>
                    <Col span={20} offset={2}>
                        <HotelTransferTable datasource={hotelsDatasource} initialHotels={hotels} onChange={onChange} />
                    </Col>
                    <Col span={2} />
                    <Col span={6} offset={17}>
                        <Button onClick={onResetHotels}>Reset</Button>
                        <Button type='primary' style={{ marginLeft: '10px' }} onClick={onFinishHotels}>Add hotels</Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    )

}

export default HotelTransferTableModal;