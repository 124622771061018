import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, message, Row, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountsAPI } from "../../api/accounts";
import { HotelsAPI } from "../../api/hotels";
import { Account, AccountHotel } from "../../models/Account";
import { Hotel } from "../../models/Hotel";
import AccountHotelTable from "../../components/AccountHotelTable/AccountHotelTable";
import HotelTransferTableModal from "./HotelTransferTableModal/HotelTransferTableModal";
import time from "../../lib/time";
import Loading from "../../components/Loading/Loading";
import withAuthRedirect from "../../components/Auth/AuthRedirect";

const AccountPage: React.FC = () => {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [account, setAccount] = useState<Account>(emptyAccount())
    const [hotels, setHotels] = useState<Hotel[]>([])

    const [isEditingAccountName, setIsEditingAccountName] = useState(false)
    const [newAccountName, setNewAccountName] = useState('')
    const [isEditingAccountType, setIsEditingAccountType] = useState(false)
    const [newAccountType, setNewAccountType] = useState('')

    const [isHotelModalOpen, setIsHotelModalOpen] = useState(false)
    const [selectedHotels, setSelectedHotels] = useState<string[]>([])

    const getAllowedHotelsWithAccountTypes = useCallback((acc: Account) => {
        const hotelsMap: Map<string, Hotel> = hotels.reduce((map, obj) => {
            map.set(obj.hotelCode, obj);
            return map;
        }, new Map<string, Hotel>())

        const newAllowedHotels = acc.allowedHotels?.map(ah => {
            const hotel = hotelsMap.get(ah.hotelCode)
            if (hotel && hotel.accountTypes) {
                ah.accountTypes = hotel.accountTypes
                return ah
            }
            ah.accountTypes = []
            return ah
        })
        
        return newAllowedHotels
    },[hotels])

    const fetchAccount = useCallback(() => {
        if (id && hotels.length > 0) {
            AccountsAPI.getAccountById(id)
                .then(a => {
                    if (a.allowedHotels && a.allowedHotels.length > 0) {
                        const newAllowedHotels = getAllowedHotelsWithAccountTypes(a)
                        a.allowedHotels = newAllowedHotels
                    }
                    setAccount(a)
                    setIsLoading(false)
                })
                .catch(e => message.error(e))
        }
    }, [id, hotels, getAllowedHotelsWithAccountTypes])

    useEffect(() => {
        fetchAccount()
    }, [id, fetchAccount])

    useEffect(() => {
        HotelsAPI.getAllHotels()
            .then(h => setHotels(h))
            .catch(e => message.error(e))
    }, [])

    const onSelectHotelsChange = (hotelCodes: string[]) => {
        setSelectedHotels(hotelCodes)
    }

    const onAddHotels = (selectedHotels: AccountHotel[]) => {
        AccountsAPI.updateAccountAllowedHotels(account.id, selectedHotels)
            .then(() => {
                fetchAccount()
                message.success('Hotels added succesfuly')
            })
    }

    const onRemoveHotel = (hotelCode: string) => {
        setIsLoading(true)
        const newHotels = account.allowedHotels.filter(h => h.hotelCode !== hotelCode)
        AccountsAPI.updateAccountAllowedHotels(account.id, newHotels)
            .then(() => {
                fetchAccount()
                message.success(`Hotel ${hotelCode} removed successfully`)
            })
    }

    const onBulkRemove = () => {
        const newHotels = account.allowedHotels?.filter(h => !selectedHotels.includes(h.hotelCode))
        AccountsAPI.updateAccountAllowedHotels(account.id, newHotels)
            .then(() => {
                fetchAccount()
                setSelectedHotels([])
                message.success(`${selectedHotels.length} hotels removed succesfuly`)
            })
    }

    const onDownloadCsv = () => {
        const csvContent = generateCsvContent(account?.allowedHotels);
        downloadCsvFile(csvContent, 'hotels.csv');
    };

    const generateCsvContent = (data: Hotel[]): string => {
        const header = 'hotelCode,hotelName\n';
        const rows = data.map((hotel) => `${hotel.hotelCode},"${hotel.hotelName}"\n`);
        return header + rows.join('');
    };

    const downloadCsvFile = (csvContent: string, fileName: string) => {
        const element = document.createElement('a');
        const file = new Blob([csvContent], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
    };

    const updateAccountName = () => {
        if (newAccountName === '' || newAccountName === account.name) {
            setIsEditingAccountName(false)
            return
        }
        const newAccount = { ...account }
        newAccount.name = newAccountName

        AccountsAPI.updateAccount(newAccount.id, newAccount)
            .then(() => fetchAccount())
            .then(() => setIsEditingAccountName(false))
    }

    const updateAccounType = () => {
        if (newAccountType === '' || newAccountType === account.type) {
            setIsEditingAccountType(false)
            return
        }
        const newAccount = { ...account }
        newAccount.type = newAccountType

        AccountsAPI.updateAccount(newAccount.id, newAccount)
            .then(() => fetchAccount())
            .then(() => setIsEditingAccountType(false))
    }

    const handleCancel = () => {
        setIsHotelModalOpen(false);
    };

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="page-container">
            <Row gutter={[24, 36]}>
                <Col span={6}>
                    <Card type="inner" title="Account" className="card" extra={<span onClick={() => setIsEditingAccountName(true)}><EditOutlined /></span>}>
                        {isEditingAccountName ?
                            <Space.Compact style={{ width: '100%' }}>
                                <Input defaultValue={account.name} onChange={event => setNewAccountName(event.target.value)} />
                                <Button type="primary" onClick={updateAccountName}>Update</Button>
                            </Space.Compact>
                            :
                            <span>{account.name}</span>}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card type="inner" title="Account Type" className="card" extra={<span onClick={() => setIsEditingAccountType(true)}><EditOutlined /></span>}>
                        {isEditingAccountType ?
                            <Space.Compact style={{ width: '100%' }}>
                                <Input defaultValue={account.type} onChange={event => setNewAccountType(event.target.value)} />
                                <Button type="primary" onClick={updateAccounType}>Update</Button>
                            </Space.Compact>
                            :
                            <span>{account.type}</span>}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card type="inner" title="Allowed Hotels count" className="card">
                        {account.allowedHotels ? account.allowedHotels.length : 0}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card type="inner" title="Last Updated" className="card">
                        {time.convertTimestampToISOString(account.lastModified)}
                    </Card>
                </Col>
                <Col span={3} offset={1}>
                    <h2>Hotels list:</h2>
                </Col>
                <Col span={2}>
                    <Button type="primary" onClick={() => setIsHotelModalOpen(true)}>Add Hotels</Button>
                </Col>
                <Col span={2}>
                    <Button onClick={onBulkRemove} disabled={selectedHotels.length < 1}>Bulk Remove</Button>
                </Col>
                <Col span={2}>
                    <Button onClick={onDownloadCsv}>Download CSV</Button>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col span={23}>
                    <AccountHotelTable datasource={account.allowedHotels} onSelectChange={onSelectHotelsChange} onRemove={onRemoveHotel} />
                </Col>
            </Row>
            <HotelTransferTableModal isOpen={isHotelModalOpen} allHotels={hotels} initialHotels={account.allowedHotels} onFinish={onAddHotels} onCancel={handleCancel} />
        </div>
    )
}

const emptyAccount = () => {
    return {
        id: '',
        name: '',
        type: '',
        status: '',
        allowedHotels: [],
        creationDate: '',
        lastModified: ''
    }
}
export default withAuthRedirect(AccountPage);