
import { Button, Radio, RadioChangeEvent } from "antd"
import { useEffect, useState } from "react"
import "./styles.css"

interface SelectActionProps {
    initialAction: string
    onNext: (action: string) => void
}
const SelectAction: React.FC<SelectActionProps> = ({ initialAction, onNext }) => {
    const [action, setAction] = useState(initialAction)

    const onChange = (e: RadioChangeEvent) => {
        setAction(e.target.value)
    };

    const onClickNext = () => {
        onNext(action)
    }

    return (
        <div className="sa-container">
            <Radio.Group onChange={onChange} defaultValue={action} size="large">
                <Radio.Button value="add">Add hotels</Radio.Button>
                <Radio.Button value="remove">Remove hotels</Radio.Button>
            </Radio.Group>

            <div className="sa-buttons">
                <Button type='primary' style={{ marginLeft: '10px' }} disabled={action === '' ? true : false} onClick={onClickNext}>Next</Button>
            </div>

        </div>
    )
}

export default SelectAction