import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { Hotel } from "../../models/Hotel"

const columns: ColumnsType<Hotel> = [
    {
        title: 'Hotel code',
        dataIndex: 'hotelCode',
        key: 'hotelCode',
        sorter: (a: Hotel, b: Hotel) => Number(a.hotelCode) - Number(b.hotelCode),
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Hotel name',
        dataIndex: 'hotelName',
        key: 'hotelName',
        sorter: (a, b) => b.hotelName.localeCompare(a.hotelName),
        sortDirections: ['ascend', 'descend', 'ascend'],
    }
]

interface HotelTableProps {
    datasource: Hotel[]
}

const HotelTable: React.FC<HotelTableProps> = ({ datasource }) => {
    return (
        <Table rowKey={'hotelCode'} columns={columns} dataSource={datasource}></Table>
    )
}

export default HotelTable;