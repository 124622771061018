import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useEffect } from 'react';

const NotFoundPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    message.error('Page not found');
    navigate('/accounts')
  }, [navigate])
  return (<></>)
}

export default NotFoundPage;
