import { Button, Table, Tag, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import Column from "antd/es/table/Column"
import React, { useState } from "react"
import time from "../../lib/time"
import { AccountHotel } from "../../models/Account"
import useTableFilter from "../TableFilter/TableFilter"
const { Text } = Typography

interface AccountHotelTableProps {
    datasource: AccountHotel[]
    onSelectChange?: (keys: string[]) => void
    onRemove?: (hotelCode: string) => void
    actionsDisabled?: boolean
}

const AccountHotelTable: React.FC<AccountHotelTableProps> = ({ datasource, onSelectChange, onRemove, actionsDisabled }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [getColumnSearchProps] = useTableFilter()

    const onSelect = (keys: React.Key[]) => {
        setSelectedRowKeys(keys);
        if (onSelectChange) {
            onSelectChange(keys.map(k => k.toString()))
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelect,
    };

    const columns: ColumnsType<AccountHotel> = [
        {
            title: 'Name',
            dataIndex: 'hotelName',
            key: 'hotelName',
            sorter: (a: AccountHotel, b: AccountHotel) => b.hotelName.localeCompare(a.hotelName),
            ...getColumnSearchProps('hotelName'),
        },
        {
            title: 'Code',
            dataIndex: 'hotelCode',
            key: 'hotelCode',
            sorter: (a: AccountHotel, b: AccountHotel) => Number(a.hotelCode) - Number(b.hotelCode),
            ...getColumnSearchProps('hotelCode'),
        },
        {
            title: 'Account Types',
            dataIndex: 'accountTypes',
            key: 'accountTypes',
            render: (accountTypes) => (
                <>
                    {accountTypes?.map((at: string) => (
                        <Tag color="blue">{at}</Tag>
                    ))}
                </>
            )
        },
        {
            title: 'Added on',
            dataIndex: 'addedOn',
            key: 'addedOn',
            render: (date) => (
                <Text>{time.convertTimestampToISOString(date)} </Text>
            )
        },
        {
            title: 'Action',
            render: (record) => (
                <Button type="link" onClick={() => {
                    if (onRemove) {
                        onRemove(record.hotelCode)
                    }
                }}>Remove</Button>
            )
        },
    ]

    return (
        <Table rowKey={'hotelCode'} rowSelection={rowSelection} dataSource={datasource} columns={actionsDisabled ? columns.slice(0, -1) : columns} style={{ width: '1300px' }}/>
    )
}

export default AccountHotelTable;