import { Button, message, Popconfirm } from "antd";
import { Hotel } from "../../../models/Hotel";
import { useEffect, useState } from "react";
import { AccountsAPI } from "../../../api/accounts";
import { Account, AccountHotel } from "../../../models/Account";
import { useQuery } from "react-query";
import AccountsTable, { AccountUpdateDataType, Status } from "./AccountsTable/AccountsTable";
import "./styles.css"
import Loading from "../../../components/Loading/Loading";
import Success from "./Success/Success";
import { useNavigate } from "react-router-dom";

interface ReviewProps {
    accountTypes: string[];
    hotels: Hotel[];
    action: string;
    onReset: () => void;
    onBack: () => void;
}
const Review: React.FC<ReviewProps> = ({ accountTypes, hotels, action, onReset, onBack }) => {
    const [accountsUpdateDataType, setAccountsUpdateDataType] = useState<Map<string, AccountUpdateDataType>>(new Map<string, AccountUpdateDataType>())
    const { data: accounts, isLoading, error } = useQuery<Account[]>('getAccountsByAccountTypes', () => AccountsAPI.getAccounts("", accountTypes, ["allowedHotels"]));
    const [isSuccess, setIsSuccess] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        accounts?.forEach(a => {
            const record = {
                key: a.id,
                name: a.name,
                type: a.type,
                currentHotels: a.allowedHotels,
                newHotels: hotels,
                status: Status.PENDING
            } as AccountUpdateDataType
            setAccountsUpdateDataType(new Map(accountsUpdateDataType.set(a.id, record)))
        })
    }, [accounts])

    // UPDATE ACCOUNTS
    const onUpdateAccounts = () => {
        const updateAllowedHotels = async () => {
            if (!accounts) {
                return
            }

            let success = true
            for (const a of accounts) {
                updateAccountItemStatus(a.id, Status.PROCESSING)
                try {
                    const newAllowedHotels = updateAllowedHotelsList(a.allowedHotels, action)
                    await AccountsAPI.updateAccountAllowedHotels(a.id, newAllowedHotels)
                    updateAccountItemStatus(a.id, Status.PROCESSED)
                } catch (e) {
                    updateAccountItemStatus(a.id, Status.ERROR)
                    message.error('Failed to update account ' + a.id + ', error: ' + e)
                    success = false;
                }
            }
            setIsSuccess(success)
        }
        updateAllowedHotels()
    }

    // UPDATE STATUS FROM AN ITEM IN THE LIST
    const updateAccountItemStatus = (id: string, status: Status) => {
        const account = accountsUpdateDataType.get(id)
        if (account) {
            account.status = status
            setAccountsUpdateDataType(new Map(accountsUpdateDataType.set(id, account)))
        }
    }

    // UPDATE SELECTED HOTELS TO THE CURRENT ALLOWED HOTEL LIST
    const updateAllowedHotelsList = (accountHotels: AccountHotel[], action: string) => {
        if (!accountHotels) {
            accountHotels = []
        }

        if (action === 'remove') {
            const hotelCodes = hotels.map(h => h.hotelCode)
            const newAccountHotels = accountHotels.filter(ah => !hotelCodes.includes(ah.hotelCode))
            return newAccountHotels
        }

        if (action === 'add') {
            const accountHotelCodes = accountHotels.map(h => h.hotelCode)
            const newHotels = hotels
                .filter(h => !accountHotelCodes.includes(h.hotelCode))
                .map(h => {
                    return {
                        hotelCode: h.hotelCode,
                        hotelName: h.hotelName
                    } as AccountHotel
                })
            if (newHotels && newHotels.length > 0) {
                return accountHotels.concat(newHotels)
            }
        }
        return accountHotels
    }

    if (error) {
        message.error('Failed to get accoutns, error: ' + error)
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className='at-content'>
            {isSuccess ?
                <Success onOk={() => navigate("/accounts")} onBack={() => setIsSuccess(false)} />
                :
                <div className="rv-container">
                    <div className="rv-title">
                        <span className="rv-text">Accounts: </span>
                    </div>
                    <AccountsTable accounts={accountsUpdateDataType} hotels={hotels} action={action} />
                    <div className="rv-buttons">
                        <Popconfirm
                            title="Reset"
                            description="Are you sure want to reset?"
                            onConfirm={onReset}
                            onCancel={() => console.log('no')}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>Reset</Button>
                        </Popconfirm>
                        <Button style={{ marginLeft: '10px' }} onClick={onBack}>Back</Button>
                        <Popconfirm
                            title="Update"
                            description="Are you sure want to update all these accounts?"
                            onConfirm={onUpdateAccounts}
                            onCancel={() => console.log('no')}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type='primary' style={{ marginLeft: '10px' }}>Update</Button>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}

export default Review;